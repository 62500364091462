import React from 'react';
import { Link } from 'gatsby';
import './Pagination.scss';

export const Pagination = (props) => {
  const { numPages, pathPrefix, currentPage } = props;
  return (
    <section className="pagination">
      <div className="wrapper">
        <ul className="pages">
          {Array.from({ length: numPages }).map((_, i) => {
            const pageLinkSlug = i === 0 ? pathPrefix : `${pathPrefix}page/${i + 1}`;
            return (
              <li className="page" key={i}>
                <Link
                  className={currentPage === i ? 'active' : ''}
                  to={`/${pageLinkSlug}/`}
                >
                  {i + 1}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}
