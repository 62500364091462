import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { Banner } from '../acf/Banner';
import PostList from '../components/PostList';
import { Pagination } from '../components/Pagination';
import './blog.scss';

export default class IndexPage extends React.Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { site, allWordpressPost: posts, siteSettings } = data;
    const { options } = siteSettings;
    const { blogBannerImage = null } = options;
    const { title: siteTitle, wordpressUrl, blogSlug } = site.siteMetadata;
    const { numberOfPages, pageNumber: currentPage } = pageContext;

    return (
      <Layout location={location} className="blog-page-template">
        <SEO title={`Latest posts | ${siteTitle}`} />
        <Banner
          image={blogBannerImage}
          title="Blog"
          wordpressUrl={wordpressUrl}
        />
        <PostList posts={posts} blogSlug={blogSlug} />
        <Pagination numPages={numberOfPages} pathPrefix={`/${blogSlug}/`} currentPage={currentPage} />
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    siteSettings: wordpressAcfOptions {
      options {
        blogBannerImage {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1200, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        wordpressUrl
        blogSlug
      }
    }
    allWordpressPost(
      sort: { fields: date, order: DESC }
      filter: { slug: { ne: "build-post" } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          categories {
            wordpress_id
            name
            slug
          }
        }
      }
    }
  }
`
