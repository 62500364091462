import React, { Component } from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import Image from './Image';
import './PostList.scss';

export default class PostList extends Component {
  render() {
    const { blogSlug, posts, categorySlug = null } = this.props
    return (
      <StaticQuery
        query={graphql`
          query {
            allCategories: allWordpressCategory(filter: { count: { gt: 0 } }) {
              edges {
                node {
                  wordpress_id
                  name
                  slug
                }
              }
            }
          }
        `}
        render={data => {
          const { allCategories } = data;
          return (
            <section className="posts-feed">
              <div className="wrapper">
                {allCategories && allCategories.edges && (
                  <div className="filters">
                    <span className="filter-by">Filter by:</span>
                    <Link className={`filter ${categorySlug ? '' : 'active'}`} to={`/${blogSlug}/`}>
                      <span>All</span>
                    </Link>
                    {allCategories.edges.map(({node: cat}) => (
                      <Link
                        className={`filter ${categorySlug === cat.slug ? 'active' : ''}`}
                        to={`/${blogSlug}/category/${cat.slug}/`}
                        key={cat.slug}
                      >
                        <span dangerouslySetInnerHTML={{__html: cat.name}} />
                      </Link>
                    ))}
                  </div>
                )}
                {posts && posts.edges ? (
                  <div className="post-items">
                    {posts.edges.map(({node: post}, postIndex) => {
                      const { title: postTitle, featuredImage, categories, slug: postSlug } = post;
                      const mainCat = categories.length > 0 ? categories[0] : null;
                      return (
                        <Link
                          className="post-item"
                          to={`/${blogSlug}/${postSlug}/`}
                          key={`${postSlug}--${postIndex}`}
                        >
                          <Image className="post-item-image" image={featuredImage} />
                          <div className="post-item-body">
                            {mainCat && <span className="post-category" dangerouslySetInnerHTML={{__html: mainCat.name}} />}
                            <h2 dangerouslySetInnerHTML={{__html: postTitle}} />
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                ) : (
                  <div className="no-posts">
                    {`No posts found.`}
                  </div>
                )}
              </div>
            </section>
          );
        }}
      />
    )
  }
}
